// import { polygonMumbai } from "wagmi/chains";

export const IssuerAddress = {
  97: "0x4F772E7A0a550B1D0D85D5EE92721946CC3D978b",
  137: "0x4F772E7A0a550B1D0D85D5EE92721946CC3D978b",
  80001: "0x4F772E7A0a550B1D0D85D5EE92721946CC3D978b",
}

export const NFTAddress = {
  97: "0xC45BB9357a9C2c96549367480E0197De82CEa426",
  137: "0xC45BB9357a9C2c96549367480E0197De82CEa426",
  80001: "0xC45BB9357a9C2c96549367480E0197De82CEa426",
}