import * as React from 'react'

const AirplaneSVG = (props) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.3 11.7v-.925c0-.358-.267-.533-.592-.391l-4.175 1.8c-.658.283-1.191-.067-1.191-.775v-1.1c0-.567.425-1.225.95-1.442l4.75-2.042a.487.487 0 0 0 .25-.391v-2.55c0-.8.591-1.759 1.3-2.125a.9.9 0 0 1 .8 0c.716.366 1.308 1.316 1.308 2.116v2.55c0 .15.117.325.25.392l.867.367L8.3 11.7Z" />
    <path
      opacity={0.4}
      d="M17.659 10.309v1.1c0 .708-.534 1.058-1.192.775l-4.175-1.8c-.333-.134-.592.033-.592.391V13.2c0 .192.109.467.25.6l1.934 1.942c.2.2.291.6.2.875l-.375 1.133a.852.852 0 0 1-1.184.492l-1.983-1.667a.866.866 0 0 0-1.092 0l-1.983 1.667A.826.826 0 0 1 6.3 17.75l-.375-1.133c-.091-.283 0-.675.2-.875L8.06 13.8a.986.986 0 0 0 .241-.6v-1.5l4.517-4.516 3.892 1.675c.525.225.95.883.95 1.45Z"
    />
  </svg>
)

export default AirplaneSVG
